.squareInfo {
  border-radius: 15px;
  background-color: white;
  border: 2px solid var(--corPrincipal);
  color: var(--corPrincipal);
  padding: 15px;
  width: 97px;
  height: 97px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  margin-right: 20px;

  @media (max-width: 576px) {
    width: 84px;
    height: 84px;
    margin-bottom: 10px;
    margin-right: 15px;
  }
  &:last-child {
    margin-right: 0;
  }
  p {
    font-size: 8px;
    line-height: 10px;
    font-size: 16px;
    line-height: 16px;
    @media (max-width: 576px) {
      font-size: 11px;
      line-height: 11px;
    }
  }
}
html[data-theme='dark'] {
  .squareInfo {
    background-color: var(--bs-body-bg);
  }
}
