.wrap {
  background-color: #fff;
  padding: 10px;
  text-align: center;
  p {
    margin: 0;
    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
  }
}

html[data-theme='dark'] {
  .wrap {
    background-color: #000;
    border-bottom: 1px solid #333;
  }
}
